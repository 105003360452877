import GlifosDigitalArchiveViewer from 'modules/glifos-digital-archive/components/GlifosDigitalArchiveViewer/GlifosDigitalArchiveViewer';
import Alert from 'modules/website/components/Alert/Alert';
import { useVideoInfoPage } from 'modules/website/hooks/useVideoInfoPage';
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import TestFile from 'assets/files/France_Nullement_Impliquee.pdf';
import { useLocation, useParams } from 'react-router-dom';
import ShareButton from 'modules/website/components/New/ShareButton/ShareButton';
import GLifosMetadata from 'modules/glifos-rich-media/components/GLifosMetadata/GLifosMetadata';
import { useDigitalArchivePage } from 'modules/website/hooks/useDigitalArchivePage';
import ApiURLs from 'services/BackendApi/BackendApiURLs';

export default function GlifosDigitalArchiveView(props) {
	const {setBreadC, title} = props;
	const location = useLocation();
	const { alias, alias2 , nid } = useParams();
	const { isLoading, isError, payload, error } = useDigitalArchivePage({
		alias: alias?`${alias}${alias2 ? "$$" + alias2 : ""}`:nid,
		stylesheet: 'DefaultJSON_digital_archive',
	});
	useEffect(() => {
        if(payload){
            //Add at the end of BreadC the title of the video
            const newBreadC = [...payload.breadC];
            newBreadC.push({title:title});
            setBreadC(newBreadC);
        }
    }, [payload]);

	//console.log(payload);
	//https://genocidearchiverwanda.org.rw/images/d/d1/France_Nullement_Impliquee.pdf
	//console.log(ApiURLs.baseURL+'/v1/file/get/' + payload?.document?.filename);
	return (
		<Container fluid>
			<Row>
				{!isLoading && !isError && payload && (
					<GlifosDigitalArchiveViewer src={ApiURLs.baseURL+'/v1/file/get/' + payload?.document?.filename} />
				)}
				{/* <GlifosDigitalArchiveViewer src={'https://api.gar.local/v1/file/get/06625ae1512578607f313477c9aecea4'} /> */}
			</Row>
			<Row>
				<ShareButton document />
			</Row>
			<Row>
				{isLoading && <div>Loading...</div>}
				{isError && <Alert variant='danger'>{error}</Alert>}
				{!isLoading && !isError && payload && (
					<>
						<Col md={12}>
							<GLifosMetadata metadata={payload.metadata} />
						</Col>
					</>
				)}
			</Row>
			{props.children}
		</Container>
	);
}
