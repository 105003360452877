import React, { useState } from 'react';
import { Pinterest } from 'react-bootstrap-icons';
import { Instagram } from 'react-bootstrap-icons';
import { Twitter } from 'react-bootstrap-icons';
import { Youtube } from 'react-bootstrap-icons';
import { Facebook } from 'react-bootstrap-icons';

import styles from './SocialMedia.module.scss';

export default function SocialMedia(props) {
	const { url, title } = props;
	const [link, setLink] = useState(window.location.href);
	let shareUrl = link + url;
	shareUrl = shareUrl.replace("//", '/');
	if (title !== undefined && url !== undefined) {
		return (
			<div className={`${styles.socials} d-flex flex-row flex-wrap gap-5 `}>
				<a
					href={'http://www.facebook.com/sharer.php?u=' + encodeURIComponent(shareUrl) + '$&t=' + encodeURIComponent(title)}
					target='_blank'
					rel='noreferrer noopener'>
					<Facebook size={16} className={`${styles.icon} text-muted`} />
				</a>
				<a
					href='https://www.youtube.com/user/GenocideArchiveRwand'
					target='_blank'
					rel='noreferrer noopener'>
					<Youtube size={16} className={`${styles.icon} text-muted`} />
				</a>
				<a
					href={'http://twitter.com/intent/tweet?text=' + encodeURIComponent(title) + '&url=' + encodeURIComponent(shareUrl)}
					target='_blank'
					rel='noreferrer noopener'>
					<Twitter size={16} className={`${styles.icon} text-muted`} />
				</a>
				<a
					href={'https://www.pinterest.com/pin/create/button/?url=' + encodeURIComponent(shareUrl) + '&description=' + encodeURIComponent(title.replace(' ', '+')) + '&ref=os'}
					target='_blank'
					rel='noreferrer noopener'>
					<Pinterest size={16} className={`${styles.icon} text-muted`} />
				</a>
				<a
					href='https://www.instagram.com/genocidearchiverwanda/'
					target='_blank'
					rel='noreferrer noopener'>
					<Instagram size={16} className={`${styles.icon} text-muted`} />
				</a>
			</div>
		);
	} else {
		return (<></>);
	}
}
