import BasicLayout from 'common/layouts/BasicLayout/BasicLayout';
import CenteredLayout from 'common/layouts/CenteredLayout/CenteredLayout';
import SideBarLayout from 'common/layouts/SideBarLayout/SideBarLayout';
import { importComponent } from 'common/loaders/importComponent';
import SideBarDocument from 'modules/glifos-rich-media/components/SideBarDocument/SideBarDocument';
import SideBarVideo from 'modules/glifos-rich-media/components/SideBarVideo/SideBarVideo';
import NotRegisteredAd from 'modules/website/components/NotRegisteredAd/NotRegisteredAd';
import { useAliasNodeType } from 'modules/website/hooks/useAliasNodeType';
//import GlifosMetadataLoading from 'modules/glifos-rich-media/components/GLifosMetadata/GlifosMetadataLoading/GlifosMetadataLoading';
/* import NewsLoading from 'modules/website/components/News/NewsLoading/NewsLoading';
import NewsnEvents from 'modules/website/components/NewsnEvents/NewsnEvents';
import BrowseByCategory from 'modules/website/components/SideBars/BrowseByCategory/BrowseByCategory';
import BrowseByGrade from 'modules/website/components/SideBars/BrowseByGrade/BrowseByGrade';
import BrowseByTopic from 'modules/website/components/SideBars/BrowseByTopic/BrowseByTopic';
import BrowseByYear from 'modules/website/components/SideBars/BrowseByYear/BrowseByYear';
import OtherReources from 'modules/website/components/SideBars/OtherReources/OtherReources';
import SideBar from 'modules/website/components/SideBars/SideBar/SideBar';
import WatchNext from 'modules/website/components/SideBars/WatchNext/WatchNext'; */
import { useNodePage } from 'modules/website/hooks/useNodePage';
import React, { Suspense, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ComponentMap } from 'routes/ComponentMap';

export default function NodeView() {
	/* const [ViewLoad, setViewLoad] = useState(null); */
	//const [leftBar] = useState(null);
	//const [rightBar, setRightBar] = useState(null);
	//const [title, setTitle] = useState(null);
	/*const [breadC, setBreadC] = useState(null);
	const [description, setDescription] = useState(null); */
	//const [component, setComponent] = useState(null);
	//const nid = location.pathname.replace('/node/', '');
	const navigate = useNavigate();
	const auth = useSelector((state) => state.auth);
	const [ViewLoad, setViewLoad] = useState(null);
	const [leftBar, setLeftBar] = useState(null);
	const [rightBar, setRightBar] = useState(null);
	const [title, setTitle] = useState(null);
	const [breadC, setBreadC] = useState(null);
	const [backBtn, setBackBtn] = useState(null);
	const [description, setDescription] = useState(null);
	const [component, setComponent] = useState(null);
	const [background, setBackground] = useState(null);
	const { nid } = useParams();
	/* const { isLoading, isError, payload, error } = useNodePage({
		nid: nid,
	}); */
	const { isLoading, isError, payload, error } = useAliasNodeType({
		alias: `node$$${nid}`,
	});

	/* useEffect(() => {
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, []); */

	useEffect(() => {
		if (!isLoading && payload) {
			const type = payload.type[0].target_id;
			//console.log(type);
			switch (type) {
				case "rich_media_archive":
					//get from ComponentMap where type is the same
					setComponent(ComponentMap.find(componente => componente.type === type));
					setLeftBar(SideBarVideo)
					//setViewLoad(RichMediaView);
					setTitle(payload.title[0].value);
					setDescription("Author: Genocide Archive Rwanda");
					break;
				case "glifos_digital_archive":
					setComponent(ComponentMap.find(componente => componente.type === type));
					setLeftBar(SideBarDocument)
					//setViewLoad(RichMediaView);
					setTitle(payload.title[0].value);
					//setDescription("Author: Genocide Archive Rwanda");
					/* setBreadC(
						[
							{
								title: 'Collections',
								path: '/collections',
							},
							{
								title: 'Documents',
								path: '/category/documents',
							},
							{
								title: payload.title[0].value,
								path: '',
							},
						]
					) */
					break;
				case "glifos_memorial":
					setComponent(ComponentMap.find(componente => componente.type === type));
					setTitle(payload.title[0].value);

				case "glifos_photographs":
					setComponent(ComponentMap.find(componente => componente.type === type));
					break;
				case "information":
					setComponent(ComponentMap.find(componente => componente.type === type));
					break;
				case "about_information":
					setComponent(ComponentMap.find(componente => componente.type === type));
					break;
				case "research_article":
					setComponent(ComponentMap.find(componente => componente.type === type));
					break;
				case "news":
					navigate('/news' + payload.alias[0].value)
					break;
				default:
					// Manejo de tipo desconocido o sin asignar
					break;
			}
		}
	}, [payload]);

	const ViewComponent = component ? importComponent(component.c) : null;

	if (isError) {
		window.location.href = '/404'
	}

	if (!isLoading && payload) {
		//console.log(payload);
		if (payload?.indexed[0].value === 'registered') {
			// To login if there is no refresh_token expiration
			if (!auth?.refresh_token_expires_on) {
				return (
					<BasicLayout>
						{/* <NotRegisteredAd alias={`${alias}${alias2 ? "/" + alias2 : ""}`} /> */}
						<NotRegisteredAd alias={`node/${nid}`} />
					</BasicLayout>
				)
			}
			// To login if the token expired
			if (new Date() > new Date(auth?.refresh_token_expires_on)) {
				return (
					<BasicLayout>
						{/* <NotRegisteredAd alias={`${alias}${alias2 ? "/" + alias2 : ""}`} /> */}
						<NotRegisteredAd alias={`node/${nid}`} />
					</BasicLayout>
				)
			}

		}
	}

	return (
		component && component.l === 'left' ? (
			<SideBarLayout
				title={title}
				breadC={breadC}
				description={description}
				leftBar={leftBar}
				rightBar={rightBar}
				backBtn={backBtn}
			>
				<Suspense fallback={<div>Loading...</div>}> <ViewComponent breadC={breadC} setBreadC={setBreadC} setBackBtn={setBackBtn} title={title} /></Suspense>
			</SideBarLayout>
		) : (
			component && component.l === 'central' ? (
				<CenteredLayout>
					<Suspense fallback={<div>Loading...</div>}> <ViewComponent breadC={breadC} setBreadC={setBreadC} title={title} /></Suspense>
				</CenteredLayout>
			) : (
				component && component.l === 'basic' ? (
					<BasicLayout>
						<Suspense fallback={<div>Loading...</div>}> <ViewComponent breadC={breadC} setBreadC={setBreadC} title={title} /></Suspense>
					</BasicLayout>
				) : (
					<></>
				)
			)
		)
	)

}
