import React, { useRef, useState } from 'react'
import { Button, Form, FormGroup, Spinner } from 'react-bootstrap'
import { ChevronRight } from 'react-bootstrap-icons'
import styles from './MessageUsForm.module.scss'
import Axios  from 'services/BackendApi/BackendApi.js';

export default function MessageUsForm() {
    const formRef = useRef(null);
    const [formData, setFormData] = useState({
        name: '',
        //lastname: '',
        email: '',
        //phone: '',
        //appointment: '',
        message: '',
        //area: acervo ? acervo : '',
    });
    const [response, setResponse] = useState({
        isLoading: false,
        isError: false,
        payload: {
            message: '',
        },
    });

    const handleChange = (e) =>
        setFormData((prevData) => ({
            ...prevData,
            [e.target.name]: e.target.value,
        }));

    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;
        if (form.checkValidity() === false) return;
        //console.log('Form data:', formData);
        sendData();
    };

    const sendData = async () => {
        try {
            setResponse((prevResponse) => ({
                ...prevResponse,
                isLoading: true,
            }));
            const { data } = await Axios.SendContactForm(formData);
            const validRespose = Boolean(data?.success);
            setResponse((prevResponse) => ({
                ...prevResponse,
                isLoading: false,
                isError: !validRespose,
                payload: {
                    message: data.message,
                },
            }));
            validRespose && resetForm();
        } catch (error) {
            console.log(error);
            setResponse((prevResponse) => ({
                ...prevResponse,
                isLoading: false,
                isError: true,
                payload: {
                    message: error?.message ?? 'Error al enviar el mensaje',
                },
            }));
        }
    };

    const resetForm = () => {
        setFormData({
            name: '',
            //lastname: '',
            email: '',
            //phone: '',
            //appointment: '',
            message: '',
            //area: '',
        });
        formRef.current.reset();
    };
    return (
        <Form className={`${styles.form}`} onSubmit={handleSubmit} ref={formRef}>
            <div className={`${styles.title}`}>Message Us</div>
            <FormGroup>
                <Form.Label>Name</Form.Label>
                <Form.Control
                    type="text"
                    name='name'
                    onChange={handleChange}
                    value={formData.name}
                    required
                />
            </FormGroup>
            <FormGroup>
                <Form.Label>Email</Form.Label>
                <Form.Control
                    type="email"
                    name='email'
                    onChange={handleChange}
                    value={formData.email}
                    required
                />
            </FormGroup>
            <FormGroup>
                <Form.Label>Message</Form.Label>
                {/* <textarea type="textarea" className={`${styles.MessageI} form-control`} /> */}
                <Form.Control
                    className={`${styles.MessageI}`}
                    as="textarea"
                    name='message'
                    onChange={handleChange}
                    value={formData.message}
                    required
                />
            </FormGroup>
            <Button variant="primary" type="submit" className={`${styles.btn}`}>
                {response.isLoading ? (
                    <>
                        <Spinner animation='border' variant='light' size='sm' />{' '}
                        Enviando mensaje...
                    </>
                ) : (
                    <>
                        <ChevronRight className={`${styles.icon}`} />
                        SEND
                    </>
                )}
            </Button>
            {!response.isLoading && response.payload.message && (
                <p
                    className={`mt-3 text-wrap fw-bold ${response.isError ? 'text-danger' : ''
                        }`}
                >
                    {response.payload.message}
                </p>
            )}
        </Form>
    )
}
