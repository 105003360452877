import React, { useEffect } from 'react'
import { useState } from 'react'
import { Button } from 'react-bootstrap'
import { CaretLeftFill, CaretRightFill } from 'react-bootstrap-icons'
import NewCard from './NewCard/NewCard'
import styles from './NewsSlide.module.scss'
import { useLastNewsPage } from 'modules/website/hooks/useLastNewsPage'
import Alert from '../../Alert/Alert'
import { formatDate } from 'modules/website/utilities/utilities'
import NewCardLoading from './NewCard/NewCardLoading/NewCardLoading'
import { useNavigate } from 'react-router-dom'

const dumpData = [
    {
        date: '15 March  2016',
        title: 'Genocide Archive Trains Young Rwandans to Index National Archives',
    },
    {
        date: '06 November  2015',
        title: 'Meet Six Rwandans Honoured for Building Peace and Reconciliation after Genocide',
    },
    {
        date: '26 October  2015',
        title: 'Genocide Archive of Rwanda Demonstrates Its Work at Transform Africa Summit',
    },
    {
        date: '18 October  2015',
        title: 'Genocide Archive of Rwanda Represented at Transform Africa Summit',
    }
]

export default function NewsSlide() {
    const { isLoading, isError, payload, error } = useLastNewsPage();
    const [count, setCount] = useState(0);
    const [left, setLeft] = useState(false);
    const [right, setRight] = useState(true);
    const [title, setTitle] = useState("")
    const [date, setDate] = useState("")
    const [nid, setNid] = useState("")
    const [alias, setAlias] = useState("")
    const navigate = useNavigate();
    const handleRightArrow = () => {
        if (count !== ((Object.entries(payload).length) - 1)) {
            setCount(count + 1);
        }
        //console.log(count)
    }

    const handleLeftArrow = () => {
        if (count !== 0) {
            setCount(count - 1);
        }
        //console.log(count)
    }

    useEffect(() => {
        if (count !== 0) {
            setLeft(true);
        } else {
            setLeft(false);
        }
        if (payload) {
            if (count !== ((Object.entries(payload).length) - 1)) {
                setRight(true);
            } else {
                setRight(false);
            }

            setTitle(payload[count].title)
            setDate(payload[count].field_news_date)
            setNid(payload[count].nid)
            //Replace /api/glifos on view_node with /news
            setAlias(payload[count].view_node.replace('/api/glifos', ''))
            
        }
    }, [count, payload])

    return (
        <div className={`${styles.news}`}>
            <div className={`${styles.title}`}>
                <div className={`${styles.titleI}`} onClick={()=>navigate('/news')}>
                    LASTEST NEWS
                </div>
            </div>
            <div className={`${styles.arrows}`}>
                {left ?
                    <Button className={`${styles.arrow}`} onClick={handleLeftArrow}><CaretLeftFill className={`${styles.icon}`} /></Button>
                    :
                    <div className={`${styles.space}`}></div>
                }
                {right ?
                    <Button style={{ marginLeft: '10px' }} className={`${styles.arrow}`} onClick={handleRightArrow}><CaretRightFill className={`${styles.icon}`} /></Button>
                    :
                    <div className={`${styles.space}`} style={{ marginLeft: '10px' }}></div>
                }
            </div>
            {isLoading && (<NewCardLoading/>)}
            {!isLoading && isError && <Alert variant='danger'>{error}</Alert>}
            {!isLoading && !isError && payload && (
                <div className={`${styles.news}`}>
                    <NewCard title={title} date={formatDate(date)} nav={"/news"+alias}/>
                </div>
            )}
        </div>
    )
}
