import React from 'react'
import { Button } from 'react-bootstrap'
import { ChevronRight } from 'react-bootstrap-icons'
import SocialMedia from '../SocialMedia/SocialMedia'
import styles from './NewCard.module.scss'
import { Link, useNavigate } from 'react-router-dom'

export default function NewCard(props) {
    const navigate = useNavigate();
    const {title, date, nav} = props

    console.log(title, date, nav)

    return (
        <div className={`${styles.card}`}>
            <div className={`${styles.date}`}>
                {date}
            </div>
            <div className={`${styles.title}`}>
                {title}
            </div>
            <Button className={`${styles.btn}`} >
                <Link to={nav}
                    style={{textDecoration: "none",color:"white"}}
                >
                <ChevronRight className={`${styles.icon}`}/>
                READ MORE
                </Link>
            </Button>
            <div className={`${styles.social}`}>
                <SocialMedia title={title} url={nav}/>
            </div>
        </div>
    )
}
